import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="favourite"
export default class extends Controller {
  static values = { listingId: Number, id: String };

  connect() {
    this.id = this.idValue;
  }
  async toggle() {      
      // Toggle classes for smooth animation
      this.element.classList.toggle("_active");

      // Perform the request
      const method = this.id ? "DELETE" : "POST";
      const url = this.id
          ? `/favourites/${this.id}`
          : `/favourites?listing_id=${this.listingIdValue}`;

      const response = await fetch(url, { method, headers: { "Accept": "application/json", 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content}, });

      if (response.ok) {
          const data = await response.json();
          this.id = data.id || null; // Update favourite ID dynamically
      } else {
          console.error("Failed to toggle favourite");
      }
  }
}
