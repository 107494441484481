import { Controller } from "@hotwired/stimulus"
import { loadCss } from "../utils/css_loader"
import { closeModal, fetchAndDisplayModal } from "../utils/modal_helpers"
import { isMobile } from "../utils/utils"

// Connects to data-controller="image-upload"
export default class extends Controller {
  static targets = ["preview", "input", "error", "signedIds"]
  static values = { existingData: Array, aspectRatio: Number, minHeight: Number, crop: Boolean, multiple: Boolean }

  signedIds = []

  async connect() {
    this.signedIdIndex = 0;

    const { default: importedHeic2any } = await import("heic2any");
    this.heic2any = importedHeic2any;

    this.initializeCropperInModalBound = this.initializeCropperInModal.bind(this);
    document.addEventListener('show.bs.modal', this.initializeCropperInModalBound);

    this.clickListener = async (event) => {
      if (event.target.id === 'crop-confirm-button') {
        await this.finalizeCrop();
      }
    };

    document.addEventListener('click', this.clickListener);

    if (this.multipleValue) {
      await this.initializeSortable()
    }

    this.initializeExistingImages();
  }

  disconnect() {
    // Remove the event listener when the controller disconnects
    document.removeEventListener('show.bs.modal', this.initializeCropperInModalBound);
    document.removeEventListener('click', this.clickListener);
  }

  handleClick() {
    this.inputTarget.click();
  }

  async initializeSortable() {
    const Sortable = (await import('sortablejs')).default;

    new Sortable(this.previewTarget, {
      animation: 150,
      ghostClass: 'sort-placeholder',
      filter: ".btn-remove-image",
      preventOnFilter: false,
      onUpdate: () => this.updateSortedImages()
    })
  }

  updateSortedImages() {
    let updatedSignedIds = []
    this.previewTarget.querySelectorAll('.image-container img').forEach((img, index) => {
      let signedIdIndex = parseInt(img.dataset.signedIdIndex)
      updatedSignedIds.push(this.signedIds[signedIdIndex])
      img.dataset.signedIdIndex = index
    });
    this.signedIds = updatedSignedIds;
    this.updateSignedIdsTarget();
  }

  removeImage(event) {
    const imageContainer = event.target.closest('.image-container');
    const img = imageContainer.querySelector('img');
  
    this.updateSignedIds(null, parseInt(img.dataset.signedIdIndex));
    imageContainer.remove();
    this.updateImageMessage();
  }

  inputChange(event) {
    const files = event.target.files;

    this.errorTarget.classList.add('d-none');

    if (this.cropValue && files.length > 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Directly set the preview with the image to crop
        this.imageSrc = e.target.result;
        fetchAndDisplayModal("image_cropper");
      };
  
      reader.readAsDataURL(files[0]);
    } else {
      Array.from(files).forEach(file => {
        this.createImagePreview(file);
      })
    }

    this.updateImageMessage();
    event.target.value = '';
  }

  async uploadImage(file, spinner, img, signedIdIndex) {
    const formData = new FormData();
    formData.append('file', file);

    try { 
      const response = await fetch('/data/attach_image', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: formData
      });
  
      if (response.ok) {
        const responseData = await response.json();
        img.style.opacity = '1'; // Make the image visible
        this.updateSignedIds(responseData.signedId, signedIdIndex);
      } else {
        console.error('Upload failed!', response.statusText);
        // Handle error (e.g., show error message)
      }
    } catch (error) {
      console.error('Error during upload:', error);
    } finally {
      spinner.remove();
    }
  }

  async handleHEIC(file) {
    return new Promise((resolve, reject) => {
      // Check if the file is in HEIC format
      if (
        file.type === "image/heic" || 
        file.type === "image/heif" || 
        file.name.endsWith(".heic") || 
        file.name.endsWith(".heif")
      ) {
        const reader = new FileReader();
  
        reader.onload = async (event) => {
          try {
            const blob = new Blob([event.target.result], { type: file.type });
            const convertedBlob = await this.heic2any({
              blob: blob,
              toType: "image/jpeg",
              quality: 1 // Set quality as needed
            });
            resolve(convertedBlob);
          } catch (error) {
            reject(new Error("Error converting HEIC image: " + error.message));
          }
        };
  
        reader.onerror = () => {
          reject(new Error("Failed to read HEIC file."));
        };
  
        reader.readAsArrayBuffer(file);
      } else {
        resolve(file);
      }
    });
  }

  async resizeImage(file, maxWidth, quality) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        let { width, height } = img;
  
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
  
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
  
        // Convert canvas to Blob
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to resize image.'));
          }
        }, 'image/jpeg', quality); // Adjust quality if needed
      };
  
      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
    });
  }

  async processImage(file) {
    try {
      const formattedBlob = await this.handleHEIC(file);
      const resizedImageBlob = await this.resizeImage(formattedBlob, 870, 0.8);
      const jpegFile = new File([resizedImageBlob], `${file.name.split('.')[0]}.jpeg`, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
      
      return jpegFile;
    } catch (error) {
      console.error('Error processing image:', error);
      throw error; // Rethrow the error to handle it outside the method if necessary
    }
  }

  async createImagePreview(input, signedId = null, fullSizeUrl = null) {
    const imageContainer = document.createElement('div');
    imageContainer.className = 'image-container bg-light rounded';

    const img = document.createElement('img');
    img.setAttribute('data-signed-id-index', this.signedIdIndex.toString());
    const imgSignedIdIndex = this.signedIdIndex;
    this.signedIdIndex++;

    img.classList.add('d-none');

    const spinner = document.createElement('div');
    spinner.className = 'spinner-border spinner-border-image text-primary';
    imageContainer.appendChild(spinner);

    const removeButton = this.createRemoveButton();
    imageContainer.appendChild(removeButton);

    if (fullSizeUrl && !isMobile()) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.appendChild(img);
      link.href = fullSizeUrl;
      imageContainer.appendChild(link);
    } else {
      imageContainer.appendChild(img);
    }

    this.updateImagePreview(imageContainer);

    if (input instanceof File) {
      const jpegFile = await this.processImage(input);
      const resizedImageBlob = await this.resizeImage(jpegFile, 250, 1);
      const newImgUrl = URL.createObjectURL(resizedImageBlob);
      img.src = newImgUrl;
      img.classList.remove('d-none');
      img.style.opacity = '0.5';

      this.uploadImage(jpegFile, spinner, img, imgSignedIdIndex);
    } else {
      img.src = input;
      
      this.updateSignedIds(signedId, imgSignedIdIndex);
      img.classList.remove('d-none');
      spinner.remove();
    }
  }

  updateImagePreview(imageContainer) {
    if (!this.multipleValue) {
      this.previewTarget.innerHTML = ''; // Clear existing content for single image mode
    }

    this.previewTarget.appendChild(imageContainer);
  }
  
  updateSignedIds(signedId, signedIdIndex) {
    if (this.multipleValue) {
      while (this.signedIds.length <= signedIdIndex) {
        this.signedIds.push(null);
      }

      this.signedIds[signedIdIndex] = signedId;
    } else {
      this.signedIds = [signedId];
    }

    this.updateSignedIdsTarget();
  }

  initializeExistingImages() {
    if (this.hasExistingDataValue) {
      this.existingDataValue.forEach(imageData => {
        this.createImagePreview(imageData.thumbUrl, imageData.signedId, imageData.fullSizeUrl);
      });
      this.updateImageMessage();
    }
  }

  // Initializes Cropper.js on the image in the modal
  async initializeCropperInModal(event) {
    if (event.target.dataset.modalType === 'image_cropper' && this.imageSrc) {
      const image = event.target.querySelector("#modal-image-to-crop");
      if (image) {
        image.src = this.imageSrc; // Set the uploaded image as the source for cropping
        
        try {
          await this.waitForImageDisplay(image);
          // Now that the image is displayed as expected, initialize Cropper
          this.setupCropper(image);
        } catch (error) {
          console.error("Error preparing image for cropping:", error);
        }
      }
    }
  }

  async waitForImageDisplay(image, timeout = 5000) {
    return new Promise((resolve, reject) => {
      let elapsed = 0;
      const interval = 100;
  
      const checkCondition = () => {
        // Check if the image has reached its expected conditions
        if (image.complete && image.naturalWidth > 0 && image.offsetWidth > 0) {
          resolve();
        } else if (elapsed >= timeout) {
          reject(new Error("Timed out waiting for image to be displayed"));
        } else {
          elapsed += interval;
          setTimeout(checkCondition, interval);
        }
      };
  
      checkCondition();
    });
  }

  async setupCropper(image) {
    const CropperModule = await import('cropperjs');
    const Cropper = CropperModule.default;
    await loadCss('/cropper.css');

    const aspectRatio = this.hasAspectRatioValue ? this.aspectRatioValue : (4 / 3);

    // Initialize Cropper.js on the image
    this.cropper = new Cropper(image, {
      aspectRatio: aspectRatio,
      autoCropArea: 1
      // additional options here
    });
  }

  async finalizeCrop() {
    if (this.cropper && this.imageSrc) {
      const croppedCanvas = this.cropper.getCroppedCanvas();

      if (this.hasMinHeightValue && croppedCanvas.height < this.minHeightValue) {
        this.errorTarget.classList.remove('d-none');
      } else {
        croppedCanvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
            this.createImagePreview(file);
            this.updateImageMessage();
          }
        }, 'image/jpeg');
      }
      
      this.imageSrc = null;
      closeModal();
    }
  }

  isDataURL(str) {
    return typeof str === 'string' && str.startsWith('data:');
  }

  updateImageMessage() {
    const zeroPhotosChosen = document.getElementById('zero-images-chosen');
    const onePhotoChosen = document.getElementById('one-image-chosen');
    const multiplePhotosChosen = document.getElementById('multiple-images-chosen');

    if (zeroPhotosChosen && onePhotoChosen && multiplePhotosChosen) {
      zeroPhotosChosen.classList.add('d-none');
      onePhotoChosen.classList.add('d-none');
      multiplePhotosChosen.classList.add('d-none');

      const emptyDiv = this.previewTarget.querySelector('.empty-div');
      if (emptyDiv) {
        this.previewTarget.removeChild(emptyDiv);
      }

      const containers = this.previewTarget.querySelectorAll('.image-container');
      const count = containers.length;

      if (count === 0) {
        zeroPhotosChosen.classList.remove('d-none');

        const div = document.createElement('div');
        div.className = 'image-container empty-div';
        this.previewTarget.appendChild(div);
      } else if (count === 1) {
        onePhotoChosen.classList.remove('d-none');
      } else {
        multiplePhotosChosen.classList.remove('d-none');
        multiplePhotosChosen.querySelector('#images-count').textContent = count;
      }
    }
  }

  createRemoveButton() {
    const removeButton = document.createElement('button');
    removeButton.type = "button";
    removeButton.className = "btn-remove-image position-absolute top-0 start-0 size-7 btn btn-light fw-normal p-0";
    removeButton.setAttribute("aria-label", "Remove image");
    removeButton.textContent = 'x';
    removeButton.dataset.action = "image-upload#removeImage";
    return removeButton;
  }

  updateSignedIdsTarget() {
    const filteredSignedIds = this.signedIds.filter(id => id != null);
    this.signedIdsTarget.value = filteredSignedIds.join(',');
  }
}
