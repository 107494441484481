import { Controller } from "@hotwired/stimulus"
import { fetchWithTurboStream } from "../utils/fetchUtils";

// Connects to data-controller="search-districts"
export default class extends Controller {
  static targets = ["checkbox", "submitButton"]

  async connect() {
    const uniqueId = `form-${Date.now()}`;
    this.element.id = uniqueId;

    const url = `/${window.currentLocale}/search_districts/form?form_id=${uniqueId}`;
    await fetchWithTurboStream(url.toString(), { method: "GET" });
    const messagesContainer = document.querySelector('#messages');
    setTimeout(() => {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }, 100);
  }

  updateButtonState() {
    const checkedBoxes = this.checkboxTargets.filter(cb => cb.checked);
    const isButtonEnabled = checkedBoxes.length > 0;
    this.submitButtonTarget.disabled = !isButtonEnabled;
  }
}
