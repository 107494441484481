import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-submission"
export default class extends Controller {
  static targets = ["phoneNumberInput", "phoneNumberError", "verifiedText", "imagesButton", "imagesError", "searchAddress", "searchAddressError", "highlightsInput", "highlightsError", "signedIds", "startTime", "startTimeError"]
  static values = {
    pushToDatalayer: Boolean,
  }

  handleSubmit(event) {
    if (!this.validateAddress(event) || !this.validateImages(event) || !this.validateHighlights(event) || !this.validateTextAreas(event) || !this.validatePhoneNumber(event)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  handlePostSubmit(event) {
    if (!this.validateImages(event, 1)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  handleCategorySubmit(event) {
    if (!this.validateImages(event, 1)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
  
  handleAppointmentSubmit(event) {
    if (!this.validateStartTime(event) || !this.validatePhoneNumber(event)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  validateTextAreas(event) { // due to iOS textarea required lacking support
    const textareas = this.element.querySelectorAll('textarea[required]');
    for (const textarea of textareas) {
      if (!textarea.value.trim()) {
        event.preventDefault();
        textarea.focus();
        textarea.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return false;
      }
    }

    return true;
  }
  
  validateAddress(event) {
    if (this.searchAddressTarget.classList.contains('search-address-invalid') && this.searchAddressTarget.required) {
      this.displayErrorAndScroll(event, this.searchAddressTarget, this.searchAddressErrorTarget);
      return false;
    }

    this.searchAddressErrorTarget.classList.add('d-none');
    return true;
  }

  validatePhoneNumber(event) {
    if (!this.hasPhoneNumberErrorTarget) return true;

    if (this.hasPhoneNumberInputTarget && !this.phoneNumberInputTarget.value){
      this.displayErrorAndScroll(event, this.phoneNumberInputTarget, this.phoneNumberErrorTarget, this.phoneNumberErrorTarget.dataset.phoneNumberErrorMessage);
      return false;
    } 

    if (this.hasVerifiedTextTarget && this.verifiedTextTarget.classList.contains('d-none')) {
      this.displayErrorAndScroll(event, this.phoneNumberInputTarget, this.phoneNumberErrorTarget, this.phoneNumberErrorTarget.dataset.verificationErrorMessage);
      return false;
    }

    this.phoneNumberErrorTarget.textContent = '';
    return true;
  }

  validateImages(event, threshold = 3) {
    const signedIds = this.signedIdsTarget.value.trim();
    if (signedIds === '' || signedIds.split(',').length < threshold) {
      this.displayErrorAndScroll(event, this.imagesButtonTarget, this.imagesErrorTarget);
      return false;
    }

    this.imagesErrorTarget.classList.add('d-none');
    return true;
  }

  validateHighlights(event) {
    if (this.hasHighlightsInputTarget) {
      const selectedOptions = Array.from(this.highlightsInputTarget.selectedOptions);
  
      if (selectedOptions.length === 0) {
        this.displayErrorAndScroll(event, null, this.highlightsErrorTarget);
        return false;
      }

      this.highlightsErrorTarget.classList.add('d-none');
    }

    return true;
  }

  validateStartTime(event) {
    if (!this.startTimeTarget.value) {
      this.displayErrorAndScroll(event, null, this.startTimeErrorTarget);
      return false;
    }

    this.startTimeErrorTarget.classList.add('d-none');

    return true;
  }

  displayErrorAndScroll(event, focusTarget, errorTarget, errorMessage) {
    event.preventDefault();
    errorTarget.classList.remove('d-none');

    if (errorMessage) {
      errorTarget.textContent = errorMessage;
    }

    if (focusTarget) {
      focusTarget.focus();
    }

    const targetToScroll = focusTarget || errorTarget;
    targetToScroll.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }
}
