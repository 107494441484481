import { Controller } from "@hotwired/stimulus"
import { joinForLocale } from "../utils/text";
import { toggleSearchButton } from "../utils/buttons";

// Connects to data-controller="search-bar"
export default class extends Controller {
  static targets = ["locationSearchInput", "locationInput", "locationSuggestions", "priceInput", "moreOptionsInput", "locationCheckbox", "bedrooms", "bathrooms", "moreOptionsCheckbox", "submitButton"]

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.handleClickOutside);
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  expandLocationInput() {
    this.toggleVisibility(this.locationInputTarget);
  }

  expandPriceInput() {
    this.toggleVisibility(this.priceInputTarget);
  }

  expandMoreOptionsInput() {
    this.toggleVisibility(this.moreOptionsInputTarget);
  }

  toggleVisibility(activeTarget) {
    // Hide all targets
    const targets = [this.locationInputTarget, this.priceInputTarget, this.moreOptionsInputTarget];
    targets.forEach(target => {
      if (target !== activeTarget) {
        target.classList.add('d-none');
      }
    });

    if (activeTarget) {
      toggleSearchButton(this.submitButtonTarget, true);

      if (activeTarget !== this.locationInputTarget) {
        activeTarget.classList.toggle('d-none');
      } else {
        if (this.locationSearchInputTarget.value) {
          this.locationSuggestionsTarget.classList.remove('d-none');
        } else {
          this.locationInputTarget.classList.remove('d-none');
        }
      }
    }
  }

  updateLocationSubtitle() {
    let selectedLocations = [];

    this.locationCheckboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        const label = checkbox.closest('.form-check').querySelector('label');
        selectedLocations.push(label.textContent.trim());
      }
    });

    const subtitle = document.getElementById("location-subtitle");
    subtitle.value = selectedLocations.length > 0 ? joinForLocale(selectedLocations) : subtitle.dataset.default;
  }

  updateMoreOptionsSubtitle() {
    const selectedOptions = [];

    [this.bedroomsTarget, this.bathroomsTarget].forEach(target => {
      if (target.value) {
        selectedOptions.push(`${target.value}+ ${target.dataset.label}`);
      }
    });

    this.moreOptionsCheckboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        selectedOptions.push(checkbox.dataset.label);
      }
    });

    const subtitle = document.getElementById("more-options-subtitle");
    subtitle.textContent = selectedOptions.length > 0 ? joinForLocale(selectedOptions) : subtitle.dataset.default;
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.toggleVisibility(null);
      toggleSearchButton(this.submitButtonTarget, false);
    }
  }

  handleLocationInput(event) {
    const isEmpty = event.currentTarget.value === "";

    this.locationSuggestionsTarget.classList.toggle("d-none", isEmpty);
    this.locationInputTarget.classList.toggle("d-none", !isEmpty); 
  }
}