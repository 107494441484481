import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="datetime-picker"
export default class extends Controller {
  static targets = ["startTime", "dateButton", "timeButton", "dateButtons", "timeButtons", "error", "prevDatesButton", "nextDatesButton", "prevTimesButton", "nextTimesButton"];

  connect() {
    this.currentDateBatchIndex = 0;
    this.currentTimeBatchIndex = 0;

    const firstDateButton = this.dateButtonTargets[0];
    if (firstDateButton) {
      this.selectedDate = firstDateButton.dataset.day;
    }
  }

  selectDay(event) {
    this.selectedDate = event.currentTarget.dataset.day;
    this.updateButtonState(event.currentTarget, this.dateButtonTargets);
    this.currentTimeBatchIndex = 0;
  }

  selectTime(event) {
    this.selectedTime = event.currentTarget.dataset.time;
    this.updateButtonState(event.currentTarget, this.timeButtonTargets);
    this.updateStartTime();
    this.errorTarget.classList.add('d-none');
  }

  updateStartTime() {
    if (this.selectedDate && this.selectedTime) {
      const startTime = `${this.selectedDate} ${this.selectedTime}`;
      this.startTimeTarget.value = startTime;
    }
  }

  updateButtonState(selectedButton, allButtons) {
    allButtons.forEach(button => {
      if (button !== selectedButton) {
        button.classList.replace('btn-primary', 'btn-light');
      }
    });
    selectedButton.classList.replace('btn-light', 'btn-primary');
  }

  previousDateBatch() {
    if (this.currentDateBatchIndex > 0) {
      this.currentDateBatchIndex -= 1;
      this.showBatch(this.dateButtonsTarget, 'date-batch', this.currentDateBatchIndex);
      this.updateDateButtons();
    }
  }

  // Show the next batch of dates
  nextDateBatch() {
    const totalBatches = this.dateButtonsTarget.querySelectorAll('.date-batch').length;
    if (this.currentDateBatchIndex < totalBatches - 1) {
      this.currentDateBatchIndex += 1;
      this.showBatch(this.dateButtonsTarget, 'date-batch', this.currentDateBatchIndex);
      this.updateDateButtons();
    }
  }

  // Show the previous batch of time slots
  previousTimeBatch() {
    if (this.currentTimeBatchIndex > 0) {
      this.currentTimeBatchIndex -= 1;
      this.showBatch(this.timeButtonsTarget, 'time-batch', this.currentTimeBatchIndex);
      this.updateTimeButtons();
    }
  }

  // Show the next batch of time slots
  nextTimeBatch() {
    const totalBatches = this.timeButtonsTarget.querySelectorAll('.time-batch').length;
    if (this.currentTimeBatchIndex < totalBatches - 1) {
      this.currentTimeBatchIndex += 1;
      this.showBatch(this.timeButtonsTarget, 'time-batch', this.currentTimeBatchIndex);
      this.updateTimeButtons();
    }
  }

  // Helper method to show the specific batch (based on index)
  showBatch(target, batchClass, index) {
    const batches = target.querySelectorAll(`.${batchClass}`);
    batches.forEach((batch, i) => {
      batch.classList.toggle('d-md-none', i !== index);
    });
  }

  updateDateButtons() {
    const totalBatches = this.dateButtonsTarget.querySelectorAll('.date-batch').length;

    // Disable/enable "previous" and "next" date buttons
    this.prevDatesButtonTarget.disabled = this.currentDateBatchIndex === 0;
    this.nextDatesButtonTarget.disabled = this.currentDateBatchIndex === totalBatches - 1;
  }

  // Disable or enable the time navigation buttons
  updateTimeButtons() {
    const totalBatches = this.timeButtonsTarget.querySelectorAll('.time-batch').length;

    // Disable/enable "previous" and "next" time buttons
    this.prevTimesButtonTarget.disabled = this.currentTimeBatchIndex === 0;
    this.nextTimesButtonTarget.disabled = this.currentTimeBatchIndex === totalBatches - 1;
  }
}
